<script>
// import "./plugins/daterangepicker.css"
// import "./plugins/daterangepicker.js"

export default {
  props: {
    dateStart: Date,
    value: String,
    startDate: String,
    endDate: String,
    dateType: String,
    config: Object,
    valueFormat: {default:"YYYY-MM-DD"}		
  },
  computed:{
    dateFormat(){
      if(this.pluginOptions.format=="dd-mm-yyyy") return "DD-MM-YYYY"
      return this.valueFormat
    },
    pluginOptions(){
      var option = {
        singleDatePicker: true,
        showDropdowns: true,
        minYear: 1901,
        maxYear: 2020
      }
      if(this.dateType=="dob") option = {
        singleDatePicker: true,
        showDropdowns: true,
        minYear: 1901,
        maxYear: 2020
      }
      return Object.assign(option,this.config||{})
    },
  },
  async mounted() {
    await Gen.loadCss("/backend/assets/node_modules/bootstrap-daterangepicker/daterangepicker.css")
    await Gen.loadScript("/backend/assets/node_modules/bootstrap-daterangepicker/daterangepicker.js")
    setTimeout(() => { this.init() }, 50);
  },
  methods:{
    init(){
      // $(this.$el).daterangepicker()
      console.log("init")
      $(this.$el).daterangepicker({ 
        opens: 'left',
        startDate: this.startDate,
        endDate: this.endDate
      }, (start, end, label)=> {
        var val_start = start.format('YYYY-MM-DD')
        var val_end = end.format('YYYY-MM-DD')
        var val = {startDate:val_start,endDate:val_end}
        this.$emit('input', val)
      });
      // $(this.$el).daterangepicker(this.pluginOptions, (start, end, label)=>{
      //   console.log(start)
      //   var val_start = start.dates(this.valueFormat, this.dateFormat)
      //   var val_end = end.dates(this.valueFormat, this.dateFormat)
      //   var val = {val_start,val_end}
      //   this.$emit('input', val)
      //   if($(this.$el).closest("form").length) $(this.$el).valid()
      // });
      // if(this.value) { $(this.$el).datepicker("update", this.value.dates(this.dateFormat)) }
    },
  },
  watch: {
    value(v){
      console.log("asdj")
      if(!v) return $(this.$el).val("");
       console.log("fajar")
      this.$emit('input', v)
      $(this.$el).val(v)
    },
  }
}
</script>

<template>
  <!-- <input type="text" class="form-control input-sm" autocomplete="off"> -->
  <div class='input-group'>
      <input type='text' class="form-control linkedCalendars" />
      <div class="input-group-append">
          <span class="input-group-text">
              <span class="ti-calendar"></span>
          </span>
      </div>
  </div>
</template>
